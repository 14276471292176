import axios, { AxiosInstance } from "axios";

export var urlPrefix = "https://api.connectly.travel/connectly-api";

if (process.env.REACT_APP_ENV === "production") {
  urlPrefix = "https://api.connectly.travel/connectly-api";
}
if (process.env.REACT_APP_ENV === "staging") {
  urlPrefix = "https://api.s.connectly.travel/connectly-api";
}

/*
 * API interfaces data props
 */

interface AddTourProps {
  sourceId: number;
  companyId: number;
  sourceTourId: number;
  slug?: number | string | null;
}

interface UpdateActiveSourceProps {
  id: number;
  status: boolean;
}

interface CheckChannelProps {
  id: number;
}

interface CheckChannelExistentProps {
  accountId: number;
  channelId: number;
}

interface UpdateCommissionProps {
  id: number;
  percent: number;
}

export interface SourcesAssignProps {
  sourceId: number;
  companyId: number;
  accountId?: number | null;
  channelId?: number | null;
  loginApi?: string | null;
  passwordApi?: string | null;
  partnerId?: number;
}

interface IntegrationUpdateProps {
  id: number;
  descriptionStatus: boolean;
  imagesStatus: boolean;
  pricesStatus: boolean;
  calendarStatus: boolean;
}

interface GetProductsProps {
  companyId: number;
  sourceId: number | null;
  channelId: number | null;
  page: number;
  size: number;
}

interface ActiveProductProps {
  id: number;
  status: boolean;
}

interface ActualizeProductProps {
  id: number;
}

/*
 * API Service (store all endpoints)
 */
class ApiService {
  /* POST */
  private axiosInstance: AxiosInstance;

  constructor() {
    const token = sessionStorage.getItem("token");

    this.axiosInstance = axios.create();

    if (token) {
      // Set the global default Authorization header
      this.axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
  }
  /*
   * Add Tour to integration
   */
  addTour = async (data: AddTourProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/products/add_tour`,
      data
    );
  };
  /*
   * update Activity state of each integration
   */
  updateActiveSource = async (data: UpdateActiveSourceProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/update_active_source`,
      data
    );
  };
  /*
   * Check channel if exist in external service
   */
  checkChannel = async (data: CheckChannelProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/check_channel`,
      data
    );
  };
  /*
   * Check if channel is assigned to another integration
   */
  checkChannelExistent = async (data: CheckChannelExistentProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/check_channel_existent`,
      data
    );
  };
  /*
   * Update status of commission (only per integration (not per product))
   */
  updateCommission = async (data: UpdateCommissionProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/update_commission`,
      data
    );
  };
  /*
   * Assigned source to company
   */
  sourcesAssign = async (data: SourcesAssignProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/sources/assign`,
      data
    );
  };
  /*
   * Update integration parameters
   */
  integrationUpdate = async (data: IntegrationUpdateProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/integration_update`,
      data
    );
  };
  /*
   * Active/inactive product
   */
  activeProduct = async (data: ActiveProductProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/products/active`,
      data
    );
  };
  /*
   * Actualize product
   */
  actualizeProduct = async (data: ActualizeProductProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/manually_update`,
      data,
      { timeout: 300000 }
    );
  };

  /* PUT */

  /* GET */

  /*
   * Get Products from channel
   */
  getProducts = async (data: GetProductsProps) => {
    return await this.axiosInstance.post(
      `${urlPrefix}/api/connectly/products/list`,
      {
        sourceId: data.sourceId,
        channelId: data.channelId,
        companyId: data.companyId,
        page: data.page,
        size: data.size,
      }
    );
  };
  /*
   * Get Companies list (from Tripsomnia)
   */
  getCompaniesList = async () => {
    return await this.axiosInstance.get(
      `${urlPrefix}/api/connectly/companies/list`
    );
  };
  /*
   * Get all integrations list
   */
  getIntegrations = async (page: number, size: number) => {
    return await this.axiosInstance.get(
      `${urlPrefix}/api/connectly/integration/list/${page}/${size}`
    );
  };
  /*
   * Get availability sources list
   */
  getSourceList = async () => {
    return await this.axiosInstance.get(
      `${urlPrefix}/api/connectly/sources/list`
    );
  };
  /*
  * Get generated example account
  */
  generateTemporaryAccount = async (companyId: number) => {
    return await this.axiosInstance.get(
      `${urlPrefix}/api/connectly/generate-example-account/${companyId}`
    )
  }
  /* DELETE */
}

export default ApiService;
