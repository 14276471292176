import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const ButtonWrapper = styled.button.attrs((props: any) => ({
    disabled: props.disabled,
  }))`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: any) => props.disabled ? 'gray' : '#46923c'};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 2px;
    
    &:hover {
      background-color: #5bb450;
    }
  
    &:active {
      background-color: #52a447;
    }
  `;
  
  export const IconWrapper = styled.span`
    margin-right: 10px;
    margin-top: 5px;
  `;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
`;

export const DropdownContent = styled.div`
  position: absolute;
  z-index: 999;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background: #f0f0f0;
  padding: 3px;
  display: block;
`;

export const DropdownItem = styled(Link)`
  display: block;
  padding: 2px 10px;
  text-decoration: none;
  align-items: center;
  margin: auto;
  justify-content: center;
  color: #333;
  
  &:hover {
    background-color: #f1f1f1;
  }
`;


export const StyledToggleButton = styled.div<{ isChecked: boolean }>`
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  padding: 2px;
  background-color: ${({ isChecked }:any) => (isChecked ? '#72bf6a' : '#E0E0E0')};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ isChecked }:any) => (isChecked ? '#0066CC' : '#C0C0C0')};
  }
`;

export const StyledToggleKnob = styled.div<{ isChecked: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ isChecked }:any) => (isChecked ? '#FFFFFF' : '#C9C9C9')};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transition: transform 0.2s;

  ${({ isChecked }:any) => isChecked && `
    transform: translateX(20px);
  `}
`;