import { useState, useEffect } from "react";
import styled from "styled-components";
import { Arrow, Select, SelectWrapper, StyledOption } from "./style";

export interface Option {
  active: boolean;
  value: string;
  label: string;
}

interface SelectProps {
  data: Option[] | undefined;
  onChange: (data: any) => void;
  selectedValue: string | null;
}
/*
 * Customized Selector
 */
const CustomSelect: React.FC<SelectProps> = ({ data, selectedValue, onChange }: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState(
    selectedValue !== null ? selectedValue : ""
  );
  const [opts, setOpts] = useState<Option[] | undefined>();

    useEffect(() => {
      setOpts(data)
    },[data])

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedOption = opts?.find(
      (opt) => opt.value.toString() === selectedValue.toString()
    );
    setSelectedOption(selectedValue);
    onChange(selectedOption);
  };

  return (
    <SelectWrapper>
      <Select value={selectedOption} onChange={handleOptionChange}>
        <StyledOption key={0} value={0}>
          Wybierz...
        </StyledOption>
        {opts?.sort((prevOpt,currOpt) => prevOpt.label.localeCompare(currOpt.label))
        .map((option) => (
          <StyledOption
            disabled={!option.active}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </StyledOption>
        ))}
      </Select>
      <Arrow />
    </SelectWrapper>
  );
};

export default CustomSelect;
