import React, {ReactElement} from "react";
import styled from 'styled-components'

const HomePage = (): ReactElement => {
    return(
        <div style={{textAlign:'center'}}>
            <HomeTitle>Witamy w Connectly!</HomeTitle>
            <HomeShortDescription>W naszym serwisie możesz zintegrować się między zewnętrznymi serwisami turystycznymi..</HomeShortDescription>
        </div>
    )
}

const HomeTitle = styled.div`
    font-size: 36px;
    margin:20px 0;
    dark
`

const HomeShortDescription = styled.div`
    font-size: 22px;
    color: #55bf6a;
`

export default HomePage