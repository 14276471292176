import { ToastOptions } from "react-toastify";
import styled from "styled-components";

export const Container = styled.div`
  margin: 15px auto;
  width: 95%;
  box-shadow: 1px 2px 14px -5px rgba(66, 68, 90, 1);
  padding: 10px;
`;

export const toastifyParameters: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
