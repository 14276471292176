import styled from 'styled-components'

export const ContainerList = styled.div`
  margin: 15px auto 80px 15px;
  width: 95%;
  box-shadow: 1px 2px 14px -5px rgba(66, 68, 90, 1);
  padding: 10px;
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`;