import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import ApiService from "../../../../api/ApiService";
import Button from "../../../../components/buttons/Button";
import IntegrationProductTile, {
  ProductProps,
} from "../../../../components/integration_product_tile/IntegrationProductTile";
import { MultiStepFormContext } from "../../../../context/MuliStepFormContext";
import { redirectToPrevNextStepOfForm } from "../../../../utils/helpers";
import Pagination from "../../../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../style/main";
import { ProcessingSpinner, Spinner } from "../../tour_cms/step_2/style";
import { ButtonGroup, IntegrationProducts, IntegrationProductsPanel, PaginationPanel, View } from "../../style/style";
import { IntegrationTile } from "../../../../components/integration_image_tile/style";

const IntegrationProductsYieldPlanetStep = (props: any) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { contextData, setContextData } = useContext(MultiStepFormContext);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [actualized, setActualized] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductProps[]>();
  const [productSize, setProductSize] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data, handleChangeFormStep } = props;
  const integrationProductsRef = useRef<HTMLDivElement>(null);

  const getProducts = () => {
    if (products && products.length === 0) setLoading(true);
    let data = {
      companyId: Number(contextData?.fill_integration_details?.companyId),
      sourceId: 1,
      channelId: null,
      page: page,
      size: size,
    };
    const apiService = new ApiService()
    apiService.getProducts(data)
      .then((response) => {
        setProducts(response.data.list);
        setProductSize(Number(response.data.rowCount));
        toast.success(
          `Pomyślnie pobrano produkty ! (Strona: ${page} ; Ilość na stronie: ${size})`
        );
      })
      .catch((err) => {
        toast.error(`Pobranie produktów nie powiodło się !`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (integrationProductsRef.current) {
      integrationProductsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [page]);

  useEffect(() => {
    if (contextData?.fill_integration_details?.companyId !== null)
      getProducts();
  }, [page, size, actualized, contextData?.fill_integration_details]);

  const handleBack = () => {
    if (actualized) setActualized(false);
    else {
      let data = {
        integrationProductsComplete: true,
      };
      setContextData({ ...contextData, integration_products: data });
      redirectToPrevNextStepOfForm(
        location.search,
        data,
        "/integrationForm/yieldPlanet/fillIntegrationDetails?",
        handleChangeFormStep
      );
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (size: number) => {
    setSize(size);
    setPage(1);
  };

  const updateProducts = () => {
    getProducts();
  };

  const addAllTours = () => {
    // setIsProcessing(true);
    // const requests: any[] = [];
    // let allActivated = true;
    // products &&
    //   products?.forEach((product) => {
    //     if (!product?.exist) {
    //       let addTourData = {
    //         sourceId: product?.company?.id,
    //         companyId: Number(contextData?.fill_integration_details?.partnerId),
    //         sourceTourId: Number(product?.idInSource),
    //         slug: null,
    //       };
    //       const apiService = new ApiService()
    //       const request = apiService.addTour(addTourData)
    //         .then(() => {})
    //         .catch(() => {
    //           allActivated = false;
    //         });
    //       requests.push(request);
    //     }
    //   });
    // Promise.all(requests)
    //   .then(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     if (allActivated) {
    //       toast.success(
    //         `Wszystkie produkty zostały dodane pomyślnie !`,
    //         toastifyParameters
    //       );
    //     } else {
    //       toast.error(
    //         `Nie udało się dodać wszystkich produktów (z Fare Harbor) do Tripsomni`,
    //         toastifyParameters
    //       );
    //     }
    //   })
    //   .catch(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     toast.error(
    //       `Nie udało się dodać wszystkich produktów (z Fare Harbor) do Tripsomni`,
    //       toastifyParameters
    //     );
    //   });
  };

  // const actualizeAllTours = () => {
  //   let isSuccessful = true;
  //   products &&
  //     products?.forEach((product) => {
  //       if (product?.exist) {
  //         let actualizeProductData = {
  //           id: Number(product?.idInTripsomnia),
  //         };
  //         ApiService.actualizeProduct(actualizeProductData)
  //           .then(() => {
  //           })
  //           .catch(() => {
  //             isSuccessful = false;
  //           });
  //       }
  //     });
  //   isSuccessful
  //     ? toast.success(`Zaktualizowano wszystkie produkty !`, toastifyParameters)
  //     : toast.error(
  //         `Nie udało się zaktualizować wszystkich produktów`,
  //         toastifyParameters
  //       );
  //   updateProducts();
  // };

  const inactiveAllTours = () => {
    // setIsProcessing(true);
    // const requests: any[] = [];
    // let allActivated = true;
    // products &&
    //   products?.forEach((product) => {
    //     if (product?.exist && product?.isActive) {
    //       let activeProductData = {
    //         id: Number(product?.idInTripsomnia),
    //         status: false,
    //       };
    //       const apiService = new ApiService()
    //       const request = apiService.activeProduct(activeProductData)
    //         .then(() => {})
    //         .catch(() => {
    //           allActivated = false;
    //         });
    //       requests.push(request);
    //     }
    //   });
    // Promise.all(requests)
    //   .then(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     if (allActivated) {
    //       toast.success(
    //         `Wszystkie produkty zostały zdezaktywowane`,
    //         toastifyParameters
    //       );
    //     } else {
    //       toast.error(
    //         `Nie udało się dezaktywować wszystkich produktów`,
    //         toastifyParameters
    //       );
    //     }
    //   })
    //   .catch(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     toast.error(
    //       `Nie udało się dezaktywować wszystkich produktów`,
    //       toastifyParameters
    //     );
    //   });
  };

  const activeAllTours = () => {
    // setIsProcessing(true);
    // const requests: any[] = [];
    // let allActivated = true;
    // products &&
    //   products?.forEach((product) => {
    //     if (product?.exist && !product?.isActive) {
    //       let activeProductData = {
    //         id: Number(product?.idInTripsomnia),
    //         status: true,
    //       };
    //       const apiService = new ApiService()
    //       const request = apiService.activeProduct(activeProductData)
    //         .then(() => {})
    //         .catch(() => {
    //           allActivated = false;
    //         });
    //       requests.push(request);
    //     }
    //   });
    // Promise.all(requests)
    //   .then(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     if (allActivated) {
    //       toast.success(
    //         `Wszystkie produkty zostały aktywowane !`,
    //         toastifyParameters
    //       );
    //     } else {
    //       toast.error(
    //         `Nie udało się aktywować wszystkich produktów`,
    //         toastifyParameters
    //       );
    //     }
    //   })
    //   .catch(() => {
    //     updateProducts();
    //     setIsProcessing(false);
    //     toast.error(
    //       `Nie udało się aktywować wszystkich produktów`,
    //       toastifyParameters
    //     );
    //   });
  };

  return (
    <View isProcessing={isProcessing}>
      <ButtonGroup>
        {String(
          contextData?.choose_integration_elements?.fromIntegrationId
        ) && (
          <IntegrationTile
            src={
              "https://www.logosvgpng.com/wp-content/uploads/2020/11/yieldplanet-logo-vector.png"
            }
            width={65}
            height={40}
            active={true}
            visible={true}
          />
        )}
        <p style={{ marginRight: "2vh" }}>
          Produkty dostępne dla kanału{" "}
          <strong>{contextData?.fill_integration_details?.channelId}</strong>
        </p>
        <Button onClick={handleBack}>Wstecz</Button>
        <Button
          onClick={() => {
            navigate("/integrations_list");
          }}
        >
          Zakończ
        </Button>
      </ButtonGroup>
      <IntegrationProducts ref={integrationProductsRef}>
        <IntegrationProductsPanel>
          <Button onClick={addAllTours}>Dodaj wszystkie</Button>
          {/* <Button onClick={actualizeAllTours}>Aktualizuj wszystkie</Button> */}
          <Button onClick={inactiveAllTours}>Wyłącz wszystkie</Button>
          <Button onClick={activeAllTours}>Włącz wszystkie</Button>
          <div
            style={{
              marginLeft: "5px",
              fontWeight: "bold",
              color: "gray",
              fontSize: "18px",
            }}
          >
            Prowizja: {contextData?.fill_integration_details?.fee}%
          </div>
        </IntegrationProductsPanel>
        {!loading ? (
          products?.map((product) => {
            return (
              <IntegrationProductTile
                key={product.idInTripsomnia}
                actualized={actualized}
                {...product}
                updateProducts={updateProducts}
                noConnIntegration={true}
              />
            );
          })
        ) : (
          <div
            style={{
              fontSize: "20px",
              color: "darkviolet",
              display: "flex",
              margin: "5vh auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Ładowanie produktów... <Spinner size="24px" color="darkviolet" />
          </div>
        )}
        {isProcessing && <ProcessingSpinner isProcessing={isProcessing} />}
        <PaginationPanel>
          <Pagination
            currentPage={page}
            totalPages={
              !isNaN(Number(productSize))
                ? Math.ceil(Number(productSize) / size)
                : 1
            }
            onPageChange={handlePageChange}
            containerStyle={{ marginTop: "50px" }}
            buttonStyle={{ border: "2px solid darkviolet" }}
            activeButtonStyle={{ backgroundColor: "darkviolet" }}
            getRowsPerPageCallback={handlePageSizeChange}
            rowsPerPageOptions={[1, 2, 5, 10, 20, 50]}
            defaultRowsPerPage={10}
          />
        </PaginationPanel>
      </IntegrationProducts>
    </View>
  );
};

export default IntegrationProductsYieldPlanetStep;
