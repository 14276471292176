import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 20px auto;
  padding: 0 16px;

  @media (max-width: 600px) {
    width: 300px;
  }
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #bababa;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #72bf6a;
    height: 4px;
    width: ${({ width }: any) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

export const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${({ step }: any) =>
    step === 'completed' ? '#72bf6a' : '#F3E7F3'};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`

export const StepCount = styled.span`
  font-size: 24px;
  color: #303030;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

export const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    top: 40px;
  }
`

export const StepLabel = styled.span`
  font-size: 12px;
  color: #4a154b;
  
  @media (max-width: 600px) {
    font-size: 10px;
  }
`