import styled from 'styled-components'

export const ChooseIntegrationElementsWrapper = styled.div`
  display: flex;
  margin: auto;
`;

export const IntegrationSection = styled.div`
  width: 40%;
  margin: auto;
`;
export const ConnectionIconSection = styled.div`
  width: 15%;
  margin: auto;
`;
export const ConnectionButtonSection = styled.div`
  width: 25%;
  margin: auto;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
`;
