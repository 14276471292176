import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  display: flex;
  top: 0;
  box-sizing: border-box;
  z-index: 999;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 8px;
  box-shadow: 1px 2px 14px -5px rgba(66, 68, 90, 1);

`;

export const Logo = styled.img`
  height: 55px;
  width: auto;
  opacity: 0.8;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  &:hover {
    opacity: 1.2;
    transform: scale(1.1);
  }
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`;