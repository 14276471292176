import React, { useContext, useEffect, useState } from "react";
import Button from "../../../../components/buttons/Button";
import {
  getQueryParamValue,
  redirectToPrevNextStepOfForm,
} from "../../../../utils/helpers";
import { useLocation } from "react-router-dom";
import {
  ButtonGroup,
  Checkbox,
  CheckboxWithLabel,
  Confirm,
  Error,
  Form,
  FormGroup,
  Info,
  Input,
  InputNumber,
  Label,
  Spinner,
} from "./style";
import CustomSelect, {
  Option,
} from "../../../../components/select/CustomSelect";
import ApiService from "../../../../api/ApiService";
import { MultiStepFormContext } from "../../../../context/MuliStepFormContext";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../style/main";

interface FormData {
  channelId: string | null;
  accountId: string | null;
  policy: boolean | null;
  partnerId: string | null;
  fee: number | null;
}

const initialFormData = {
  channelId: "",
  accountId: "",
  policy: false,
  partnerId: "",
  fee: 20,
};

const IntegrationDetailsStep = (props: any) => {
  let location = useLocation();
  const queryParams = location.search;
  const { handleChangeFormStep } = props;
  const { contextData, setContextData } = useContext(MultiStepFormContext);
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
  const [companies, setCompanies] = useState<Option[]>();
  const [isChannelAvailable, setIsChannelAvailable] = useState<boolean | null>(
    null
  );
  const [isChannelExistent, setIsChannelExistent] = useState<boolean | null>(
    null
  );
  const [formData, setFormData] = useState<FormData>(() => {
    if (queryParams) {
      return {
        channelId: getQueryParamValue(queryParams, "channelId"),
        accountId: getQueryParamValue(queryParams, "accountId"),
        policy: getQueryParamValue(queryParams, "policy") === "true",
        partnerId: getQueryParamValue(queryParams, "partnerId"),
        fee:
          getQueryParamValue(queryParams, "fee") === null
            ? 10
            : Number(getQueryParamValue(queryParams, "fee")),
      };
    } else return initialFormData;
  });

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any
    >
  ) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setIsChannelAvailable(null);
    setIsChannelExistent(null);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getCompanies = () => {
    const apiService = new ApiService()
    apiService.getCompaniesList()
      .then((response) => {
        const optionsData: Option[] = response.data.map((option: any) => ({
          value: option.id,
          label: option.name,
          active: option.active,
        }));
        setCompanies(optionsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const verify = () => {
    setIsVerificationLoading(true);
    const checkChannelData = {
      id: Number(formData.channelId),
    };
    const checkChannelExistenceData = {
      accountId: Number(formData.accountId),
      channelId: Number(formData.channelId),
    };
    const apiService = new ApiService()
    apiService.checkChannel(checkChannelData)
      .then((response) => {
        setIsChannelAvailable(response.data.status);
        response.data.status
          ? toast.success(
              `Channel id: ${formData.channelId} istnieje`,
              toastifyParameters
            )
          : toast.error(
              `Channel id: ${formData.channelId} nie istnieje`,
              toastifyParameters
            );
      })
      .catch((err) => {
        setIsChannelAvailable(false);
      })
      .finally(() => {
        const apiService = new ApiService()
    apiService.checkChannelExistent(checkChannelExistenceData)
          .then((response) => {
            setIsChannelExistent(response.data.company !== null);
          })
          .catch((err) => {
            setIsChannelExistent(err?.response?.data?.company !== null);
          })
          .finally(() => {
            setIsVerificationLoading(false);
          });
      });
  };

  const changeFee = (companySourceId: number) => {
    let updateCommissionData = {
      id: companySourceId,
      percent: formData?.fee === null ? 10 : Number(formData?.fee)
    }
    const apiService = new ApiService()
    apiService.updateCommission(updateCommissionData)
      .then((response) => {
        toast.success(`Zmiana prowizji na ${formData?.fee === null ? 10 : Number(formData?.fee)}%`, toastifyParameters);
      })
      .catch((err) => {
        toast.error(`Nie udało się zmienić prowizji na ${contextData?.fill_integration_details?.fee}%`, toastifyParameters);
      })
}

  const assignSource = () => {
    // if(isChannelExistent){
    //   setContextData({ ...contextData, fill_integration_details: formData });
    //   redirectToPrevNextStepOfForm(
    //     location.search,
    //     formData,
    //     "/integrationForm/tourCMS/integrationProducts?",
    //     handleChangeFormStep
    //   );
    // }else{
      let assignSourceData = {
        sourceId: 2,
        companyId: Number(formData.partnerId),
        accountId: Number(formData.accountId),
        channelId: Number(formData.channelId),
      };
      const apiService = new ApiService()
    apiService.sourcesAssign(assignSourceData)
        .then((response) => {
          toast.success(`Udało się wykonać integracje !`)
          setIsChannelAvailable(true);
          setContextData({ ...contextData, fill_integration_details: formData });
          let companySourceId: number = response.data.id
          changeFee(companySourceId)
          setTimeout(() => {
            redirectToPrevNextStepOfForm(
              location.search,
              formData,
              "/integrationForm/tourCMS/integrationProducts?",
              handleChangeFormStep
            );
          },1500)
        })
        .catch((err) => {
          toast.error(`Nie udało się wykonać integracji`)
        })
    // }
  };

  const handleBack = () => {
    setContextData({ ...contextData, fill_integration_details: formData });
    redirectToPrevNextStepOfForm(
      location.search,
      formData,
      "/integrationForm/chooseIntegrationElements?",
      handleChangeFormStep
    );
  };

  const handleSelectChange = (selected: {
    active: string;
    label: string;
    value: string;
  }) => {
    setIsChannelAvailable(null);
    setIsChannelExistent(null);
    setFormData({
      ...formData,
      partnerId: selected.value,
    });
  };

  return (
    <Form>
      <FormGroup>
        {isChannelAvailable !== null ? (
          isChannelAvailable ? (
            <Confirm>
              <Label>
                Kanał {formData.channelId} został podłączony z kontem Tripsomnia
                na Tour CMS
              </Label>
            </Confirm>
          ) : (
            <Error>
              <Label>
                Kanał {formData.channelId} nie jest podłączony z kontem
                Tripsomnia na Tour CMS
              </Label>
            </Error>
          )
        ) : null}
        {isChannelExistent !== null ? (
          isChannelExistent ? (
            <Info>
              <Label>
                Kanał {formData.channelId} został podpięty do innego konta
                Partnera na Tripsomnia
              </Label>
            </Info>
          ) : (
            <Info>
              <Label>
                Kanał {formData.channelId} nie jest podpięty do innego konta
                Partnera na Tripsomnia
              </Label>
            </Info>
          )
        ) : null}
        <Label htmlFor="channel-id">Channel ID</Label>
        <Input
          type="text"
          id="channel-id"
          name="channelId"
          value={formData.channelId}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="account-id">Account ID</Label>
        <Input
          type="text"
          id="account-id"
          name="accountId"
          value={formData.accountId}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <CheckboxWithLabel>
          <Checkbox
            id="policy"
            name="policy"
            value={formData.policy}
            checked={Boolean(formData.policy)}
            onChange={handleChange}
          />
          <Label htmlFor="policy">I Agree with Connectly Policy?</Label>
        </CheckboxWithLabel>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="partner-id">Partner</Label>
        <CustomSelect
          data={companies}
          selectedValue={formData.partnerId}
          onChange={(selected) => handleSelectChange(selected)}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="account-id">Domyślna prowizja</Label>
        <InputNumber
          type="number"
          id="fee-id"
          name="fee"
          value={formData.fee}
          onChange={handleChange}
        />
      </FormGroup>
      <ButtonGroup>
        <Button disabled={isVerificationLoading} onClick={handleBack}>
          Wstecz
        </Button>
        {!isChannelAvailable ? (
          <Button
            disabled={Object.values(formData).some(
              (param) => param === null || param === "" || param === false
            )}
            onClick={verify}
          >
            {isVerificationLoading ? <Spinner /> : "Weryfikacja"}
          </Button>
        ) : (
          <Button onClick={assignSource}>Dalej</Button>
        )}
      </ButtonGroup>
    </Form>
  );
};

export default IntegrationDetailsStep;
