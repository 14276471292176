import { useLocation } from "react-router-dom";

export const jsonToQueryParams = (obj: any): string => {
  const queryParams = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];
      if(val !== null) queryParams.append(key, val);
    }
  }
  return queryParams.toString();
};

export const getQueryParamValue = (
  queryParams: string,
  variableName: string
) => {
  const params = new URLSearchParams(queryParams);
  return params.get(variableName);
};

export const removeDuplicateParams = (
  currQueryParamString: string,
  newQueryParamString: string | null
): string => {
  if (newQueryParamString === null)
    return decodeURIComponent(currQueryParamString);
  const currParams = new URLSearchParams(currQueryParamString);
  const newParams = new URLSearchParams(newQueryParamString);

  const mergedParams = new URLSearchParams(currParams.toString());

  newParams.forEach((value: string, key: string) => {
    if (!currParams.has(key)) {
      mergedParams.append(key, value);
    } else {
      mergedParams.set(key, value);
    }
  });

  return mergedParams.toString();
};

export const redirectToPrevNextStepOfForm = (
  locationSearch: string,
  formData: object | null,
  redirectUrlWithoutQueryParams: string,
  handleChangeFormStep: (url: string) => void
) => {
  const currLocation = locationSearch;
  const queryParameters = formData && jsonToQueryParams(formData);
  const outputSearchParams = removeDuplicateParams(
    `${currLocation}`,
    `${queryParameters}`
  );
  handleChangeFormStep(
    decodeURIComponent(`${redirectUrlWithoutQueryParams}${outputSearchParams}`)
  );
};


export const convertCurrencyNameToSign = (currencyName: string) => {
  switch(currencyName){
    case "EUR": return "€"
    case "USD": return "$"
    case "GBP": return "£"
  }
}


interface QueryParams {
  [key: string]: string;
}

export const useQueryParams = (): QueryParams => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const params: QueryParams = {};

  queryParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};