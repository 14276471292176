import styled from 'styled-components'

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding:5px;
  box-shadow: 1px 2px 14px -5px rgba(66, 68, 90, 1);
  transition: all 1s ease-in-out;
`;

export const Logo = styled.img`
  width: 180px;
  height: 50px;
  object-fit: contain;

  opacity: 0.8;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  &:hover {
    opacity: 1.2;
    transform: scale(1.1);
  }
`;