import React, { ReactElement, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import HomePage from "./pages/home_page/HomePage";
import IntegrationFormPage from "./pages/integration_form_page/IntegrationFormPage";
import IntegrationsListViewPage from "./pages/integrations_list_page/IntegrationsListPage";
import IntegrationProductsTourCmsPage from "./pages/integration_products_page/tour_cms/IntegrationProductsTourCmsPage";
import IntegrationProductsBokunPage from "./pages/integration_products_page/bokun/IntegrationProductsBokunPage";
import IntegrationProductsFareHarborPage from "./pages/integration_products_page/fare_harbor/IntegrationProductsFareHarborPage";
import { useQueryParams } from "./utils/helpers";
import IntegrationProductsHotresPage from "./pages/integration_products_page/hotres/IntegrationProductsHotresPage";
import IntegrationProductsYieldPlanetPage from "./pages/integration_products_page/yield_planet/IntegrationProductsYieldPlanetPage";
  
const AppRoutes = (): ReactElement => {
    const queryParams = useQueryParams();
    useEffect(() => {
      const token = queryParams['token'];
      const email = queryParams['email']
      if(token) sessionStorage.setItem('token', token)
      if(email) sessionStorage.setItem('email', email)
    },[queryParams])
    
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            {/* Integration List */}
            <Route path="/integrations_list" element={<IntegrationsListViewPage />} />
            {/* Integration Products List (TourCMS)*/}
            <Route path="/integrationProducts/tourCMS/:channelId/:accountId/:companyId/:companySourceId/:connectlySourceId/:fee" element={<IntegrationProductsTourCmsPage />} />
            {/* Integration Products List (Bokun)*/}
            <Route path="/integrationProducts/bokun/:companyId/:companySourceId/:connectlySourceId/:fee" element={<IntegrationProductsBokunPage />} />
            {/* Integration Products List (Fare Harbor)*/}
            <Route path="/integrationProducts/fareHarbor/:companyId/:companySourceId/:connectlySourceId/:fee" element={<IntegrationProductsFareHarborPage />} />
            {/* Integration Products List (Hotres)*/}
            <Route path="/integrationProducts/hotres/:companyId/:companySourceId" element={<IntegrationProductsHotresPage />} />
            {/* Integration Products List (Yield Planet)*/}
            <Route path="/integrationProducts/yieldPlanet/:companyId/:companySourceId" element={<IntegrationProductsYieldPlanetPage />} />
            {/* First Step Integration Form */}
            <Route path="/integrationForm/chooseIntegrationElements" element={<IntegrationFormPage />} />
            {/* TourCMS - Second and Third Steps of Integration Form */}
            <Route path="/integrationForm/tourCMS/fillIntegrationDetails" element={<IntegrationFormPage />} />
            <Route path="/integrationForm/tourCMS/integrationProducts" element={<IntegrationFormPage />} />
            {/* Bokun - Second and Third Steps of Integration Form */}
            <Route path="/integrationForm/bokun/fillIntegrationDetails" element={<IntegrationFormPage />} />
            <Route path="/integrationForm/bokun/integrationProducts" element={<IntegrationFormPage />} />
            {/* Fare Harbor - Second and Third Steps of Integration Form */}
            <Route path="/integrationForm/fareHarbor/fillIntegrationDetails" element={<IntegrationFormPage />} />
            <Route path="/integrationForm/fareHarbor/integrationProducts" element={<IntegrationFormPage />} />
            {/* Hotres - Second and Third Steps of Integration Form */}
            <Route path="/integrationForm/hotres/fillIntegrationDetails" element={<IntegrationFormPage />} />
            <Route path="/integrationForm/hotres/integrationProducts" element={<IntegrationFormPage />} />
            {/* Yield Planet - Second and Third Steps of Integration Form */}
            <Route path="/integrationForm/yieldPlanet/fillIntegrationDetails" element={<IntegrationFormPage />} />
            <Route path="/integrationForm/yieldPlanet/integrationProducts" element={<IntegrationFormPage />} />
        </Routes>
    )
}

export default AppRoutes;