import { createContext } from 'react';

/*
 * Multi step form context which management data from each step in parent 
 * (for possibility to manage data from x step to y step of form)
 */
export const MultiStepFormContext = createContext<any>({
  contextData: {
    choose_integration_elements: null,
    fill_integration_details: null,
    integration_products: null,
  },
  setContextData: () => {},
});