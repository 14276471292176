import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/connectly_logo.png'
import { FooterWrapper, Logo } from './style';

const Footer: React.FC = () => {

    return (
        <FooterWrapper>
            <Logo src={logo} alt="Logo" />
        </FooterWrapper>
    );
}

export default Footer;