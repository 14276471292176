import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { HeaderButtonProps } from '../header/Header';
import { Dropdown, DropdownContent, DropdownItem } from './style';

const DropdownButton: React.FC<any> = ({buttons}) => {
    let navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown>
      <Button onClick={handleButtonClick}>Menu</Button>
      {isOpen && (
        <DropdownContent>
          {
            buttons.map((button:HeaderButtonProps, index:number) => {
              return (
                <DropdownItem to={button.endpoint} key={button.endpoint}>
                  <Button key={index} onClick={() => {
                    handleButtonClick()
                    navigate(button.endpoint) }}>{button.label}</Button>
                </DropdownItem>
              )
            })
          }
        </DropdownContent>
      )}
    </Dropdown>
  );
};

export default DropdownButton