import { ReactElement } from "react";
import Button from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { FaBuilding, FaPlusCircle } from "react-icons/fa";
import IntegrationTableList from "../../components/integrations/list/IntegrationTableList";
import { ButtonsSection, ContainerList } from "./style";

/*
 * Integrations List Page (with Table)
 */
const IntegrationsListPage = (): ReactElement => {
  const navigate = useNavigate();
  
  const handleNavigationToPartnerApp = () => {
    window.location.assign("www.panel.tripsomnia.com");
  };

  const actionsButtons = () => {
    return (
      <ButtonsSection>
        <Button
          onClick={() => navigate("/integrationForm/chooseIntegrationElements")}
          icon={<FaPlusCircle size={24} />}
        >
          Nowa integracja
        </Button>
        <Button
          onClick={handleNavigationToPartnerApp}
          icon={<FaBuilding size={24} />}
        >
          Wróć do Panelu Partnera
        </Button>
      </ButtonsSection>
    );
  };

  return (
    <ContainerList>
      {actionsButtons()}
      <IntegrationTableList />
    </ContainerList>
  );
};

export default IntegrationsListPage;
