import React, { useContext, useEffect, useState } from "react";
import Button from "../../../../components/buttons/Button";
import {
  getQueryParamValue,
  redirectToPrevNextStepOfForm,
} from "../../../../utils/helpers";
import { useLocation } from "react-router-dom";
import {
  ButtonGroup,
  Checkbox,
  CheckboxWithLabel,
  Form,
  FormGroup,
  Input,
  InputNumber,
  Label,
} from "../../tour_cms/step_2/style";
import CustomSelect, {
  Option,
} from "../../../../components/select/CustomSelect";
import ApiService from "../../../../api/ApiService";
import { MultiStepFormContext } from "../../../../context/MuliStepFormContext";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../style/main";

interface FormData {
  companyId: string | null;
  login: string | null;
  password: string | null;
  policy: boolean | null;
}

const initialFormData = {
  companyId: "",
  login: "",
  password: "",
  policy: false,
};

const IntegrationDetailsYieldPlanetStep = (props: any) => {
  let location = useLocation();
  const queryParams = location.search;
  const { handleChangeFormStep } = props;
  const { contextData, setContextData } = useContext(MultiStepFormContext);
  const [isVerificationLoading, setIsVerificationLoading] = useState(false);
  const [companies, setCompanies] = useState<Option[]>();
  const [formData, setFormData] = useState<FormData>(() => {
    if (queryParams) {
      return {
        accountName: getQueryParamValue(queryParams, "accountName"),
        policy: getQueryParamValue(queryParams, "policy") === "true",
        companyId: getQueryParamValue(queryParams, "companyId"),
        login: getQueryParamValue(queryParams, "login"),
        password: getQueryParamValue(queryParams, "password"),
      };
    } else return initialFormData;
  });

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any
    >
  ) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getCompanies = () => {
    const apiService = new ApiService();
    apiService
      .getCompaniesList()
      .then((response) => {
        const optionsData: Option[] = response.data.map((option: any) => ({
          value: option.id,
          label: option.name,
          active: option.active,
        }));
        setCompanies(optionsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const assignSource = () => {
    let assignSourceData = {
      sourceId: 1,
      companyId: Number(formData.companyId),
      loginApi: formData.login,
      passwordApi: formData.password,
    };
    const apiService = new ApiService();
    apiService
      .sourcesAssign(assignSourceData)
      .then((response) => {
        toast.success(`Udało się wykonać integracje !`, toastifyParameters);
        setContextData({ ...contextData, fill_integration_details: formData });
        let companySourceId: number = response.data.id;
        setTimeout(() => {
          redirectToPrevNextStepOfForm(
            location.search,
            formData,
            "/integrationForm/yieldPlanet/integrationProducts?",
            handleChangeFormStep
          );
        }, 1500);
      })
      .catch((err) => {
        toast.error(`Nie udało się wykonać integracji`, toastifyParameters);
      });
  };

  const handleBack = () => {
    setContextData({ ...contextData, fill_integration_details: formData });
    redirectToPrevNextStepOfForm(
      location.search,
      formData,
      "/integrationForm/chooseIntegrationElements?",
      handleChangeFormStep
    );
  };

  const handleSelectChange = (selected: {
    active: string;
    label: string;
    value: string;
  }) => {
    setFormData({
      ...formData,
      companyId: selected.value,
    });
  };
  
  const disableButton = formData.policy ? true : false

  return (
    <Form>
      <FormGroup>
        <Label htmlFor="company-id">Company Id</Label>
        <CustomSelect
          data={companies}
          selectedValue={formData.companyId}
          onChange={(selected) => handleSelectChange(selected)}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="accountName-id">Login</Label>
        <Input
          type="text"
          id="login"
          name="login"
          value={formData.login}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="accountName-id">Password</Label>
        <Input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <CheckboxWithLabel>
          <Checkbox
            id="policy"
            name="policy"
            value={formData.policy}
            checked={Boolean(formData.policy)}
            onChange={handleChange}
          />
          <Label htmlFor="policy">I Agree with Connectly Policy?</Label>
        </CheckboxWithLabel>
      </FormGroup>
      <ButtonGroup>
        <Button disabled={isVerificationLoading} onClick={handleBack}>
          Wstecz
        </Button>
        <Button disabled={!disableButton} onClick={assignSource}>Przypisz</Button>
      </ButtonGroup>
    </Form>
  );
};

export default IntegrationDetailsYieldPlanetStep;
