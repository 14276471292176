import React from 'react';
import styled from 'styled-components';
import { ButtonWrapper, IconWrapper } from './style';

interface ButtonProps {
  disabled?: boolean;
  onClick?: (data?: any) => void;
  icon?: React.ReactNode;
  type?: string;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ onClick, icon, type, disabled, children }) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} type={type}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </ButtonWrapper>
  );
};



export default Button;