import React from 'react'
import styled from 'styled-components'
import { ProductProps } from '../IntegrationProductTile';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { InfoSummary, Label, LabelBold, ProductElementAction, ProductElementInfo, ProductIntegrationDetails, ProductStatusSummary } from './style';
import { getSourceNameById } from '../actions/IntegrationProductInfoActions';

/*
 * Integration Product info summary in tile (Statuses of each product in integration)
 */
const IntegrationProductInfoSummary = (props: ProductProps) => {
  const {
    channelId,
    channelName,
    company,
    idInSource,
    idInTripsomnia,
    title,
    description,
    exist,
    isActive,
    price,
    saleCurrency,
    location,
    thumbnailImage,
    image,
    duration,
    fee,
    actualized
  } = props;
    return (
    <InfoSummary>
        <ProductIntegrationDetails>
            <ProductElementAction>
                <LabelBold>Akcja</LabelBold>
                <Label>Dodawanie oferty</Label>
                <Label>Opis</Label>
                <Label>Zdjęcia</Label>
                <Label>Cennik</Label>
                <Label>Kalendarz</Label>
            </ProductElementAction>
            <ProductStatusSummary>
                <LabelBold>Status</LabelBold>
                {exist ? <AiFillCheckCircle style={{marginTop:'3px'}} size={23} color='green'/> : <AiFillCloseCircle style={{marginTop:'3px'}} size={23} color='red'/>}
                {exist ? <AiFillCheckCircle style={{marginTop:'3px'}} size={23} color='green'/> : <AiFillCloseCircle style={{marginTop:'3px'}} size={23} color='red'/>}
                {exist ? <AiFillCheckCircle style={{marginTop:'3px'}} size={23} color='green'/> : <AiFillCloseCircle style={{marginTop:'3px'}} size={23} color='red'/>}
                {exist ? <AiFillCheckCircle style={{marginTop:'3px'}} size={23} color='green'/> : <AiFillCloseCircle style={{marginTop:'3px'}} size={23} color='red'/>}
                {exist ? <AiFillCheckCircle style={{marginTop:'3px'}} size={23} color='green'/> : <AiFillCloseCircle style={{marginTop:'3px'}} size={23} color='red'/>}
            </ProductStatusSummary>
            <ProductElementInfo>
                <LabelBold>Informacje</LabelBold>
                <Label>{getSourceNameById(company?.id)} ID: {idInSource}</Label>
                <Label>Tour ID: {idInTripsomnia}</Label>
            </ProductElementInfo>
        </ProductIntegrationDetails>
    </InfoSummary>
    )
}

export default IntegrationProductInfoSummary