import { getUrlWithoutQueryParams } from "../../../../integrations/funcs";

export const fareHarborSteps = [
  {
    label: "Integration Connection",
    step: 1,
  },
  {
    label: "Integration Details",
    step: 2,
  },
  {
    label: "Integration Products",
    step: 3,
  },
];

export const renderFormSteps = (activeStep: number,changeStep: (url:string) => void) => {
//   if(activeStep === 1) return <ChooseIntegrationElementsStep handleChangeFormStep={changeStep} />
//   if(activeStep === 2) return <IntegrationDetailsStep handleChangeFormStep={changeStep} />
//   if(activeStep === 3) return <IntegrationProductsStep handleChangeFormStep={changeStep} />
}

export interface FareHarborProps {
  choose_integration_elements: {
    fromIntegrationId: string | null;
    toIntegrationId: string | null;
  };
  fill_integration_details: {
    accountId: number | null;
    token: string | null;
    partnerId: string | null;
    policy: boolean | null;
    fee: number | null;
  };
  integration_products: {
    integrationProductsComplete: any;
  };
}


export const setActiveStepByUrlOfFareHarborIntegration = (pathname: string, setActiveStep: (step: number) => void) => {
  let stepPath = getUrlWithoutQueryParams(pathname)[0];
  if (stepPath === "/integrationForm/chooseIntegrationElements") {
    setActiveStep(1);
  } else if (stepPath === "/integrationForm/fareHarbor/fillIntegrationDetails") {
    setActiveStep(2);
  } else if (stepPath === "/integrationForm/fareHarbor/integrationProducts") {
    setActiveStep(3);
  }
}

export const getFareHarborParamsFromUrlQueryParams = (searchParams: any) => {
  const fromIntegrationId = searchParams.get("fromIntegrationId") || null;
  const toIntegrationId = searchParams.get("toIntegrationId") || null;
  const accountId = searchParams.get("accountId") || null;
  const token = searchParams.get("token") || null;
  const partnerId = searchParams.get("partnerId") || null;
  const policy = Boolean(searchParams.get("policy"));
  const fee = Number(searchParams.get("fee"));
  const integrationProductsComplete = searchParams.get(
    "integrationProductsComplete"
  );
  return {
    choose_integration_elements: {
      fromIntegrationId,
      toIntegrationId,
    },
    fill_integration_details: {
      accountId,
      token,
      partnerId,
      policy,
      fee,
    },
    integration_products: {
      integrationProductsComplete,
    },
  };
};

export const redirectToProperlyStepUrlFareHarbor = (
  formStepPathname: string,
  queryParams: string,
  navigate: any
) => {
  const queryParameters = new URLSearchParams(queryParams);
  if (formStepPathname === "/integrationForm/chooseIntegrationElements") {
    navigate(
      decodeURIComponent(
        `/integrationForm/chooseIntegrationElements?${queryParameters}`
      )
    );
  } else if (
    formStepPathname === "/integrationForm/fareHarbor/fillIntegrationDetails"
  ) {
    navigate(
      decodeURIComponent(
        `/integrationForm/fareHarbor/fillIntegrationDetails?${queryParameters}`
      )
    );
  } else if (
    formStepPathname === `/integrationForm/fareHarbor/integrationProducts`
  ) {
    navigate(
      decodeURIComponent(
        `/integrationForm/fareHarbor/integrationProducts?${queryParameters}`
      )
    );
  }
};

export const initialFareHarborData: FareHarborProps | undefined = {
  choose_integration_elements: {
    fromIntegrationId: null,
    toIntegrationId: null,
  },
  fill_integration_details: {
    accountId: null,
    token: null,
    partnerId: null,
    policy: false,
    fee: 0,
  },
  integration_products: {
    integrationProductsComplete: false,
  },
};
