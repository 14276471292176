import { BrowserRouter, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import styled from "styled-components";
import { headerButtons } from "./data/data";
import logoImg from "./assets/connectly_logo.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const App = (): JSX.Element => {

  return (
    <BrowserRouter>
      <Header
        avatar="https://img.freepik.com/free-icon/avatar_318-158392.jpg"
        logo={logoImg}
        buttons={headerButtons}
      />
      <ContentContainer>
        <AppRoutes />
      </ContentContainer>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
};

const ContentContainer = styled.div``;

export default App;
