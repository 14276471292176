import styled, { keyframes, css } from "styled-components";

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 2vh;
  min-width: 300px;
  border-radius: 10px;
  box-shadow: 1px 2px 14px -5px rgba(66, 68, 90, 1);
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  margin-bottom: 2px;
  font-size: 13px;
`;

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
`;

export const InputNumber = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 500;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
  border: 2px solid darkviolet;
  margin: -2px 5px;
  border-radius: 5px;
  padding: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:checked {
    background-color: green;
  }
`;

export const Option = styled.option`
  font-weight: 500;
`;

export const ButtonGroup = styled.div`
  justify-conent: center;
  margin: auto;
  display: flex;
  align-items: center;
`;

export const CheckboxWithLabel = styled.span`
  display: flex;
  font-weight: 500;
`;

export const Error = styled.div`
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
`;

export const Confirm = styled.div`
  color: green;
  border: 1px solid green;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
`;

export const Info = styled.div`
  color: blue;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
`;
interface SpinnerProps {
  size?: string;
  color?: string;
}

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<SpinnerProps>`
  display: inline-block;
  width: ${(props: any) => props.size || "20px"};
  height: ${(props: any) => props.size || "20px"};
  border-radius: 50%;
  border: 3px solid ${(props: any) => props.color || "#fff"};
  border-top-color: transparent;
  animation: ${rotate} 1.2s linear infinite;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Create a styled Spinner component using the rotateAnimation
export const ProcessingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid black;
  border-top-color: black;
  animation: ${({ isProcessing }: any) =>
    isProcessing
      ? css`
          ${rotateAnimation} 1s linear infinite
        `
      : "none"};
`;
