import { useContext, useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import { FaPlusCircle } from "react-icons/fa";
import {
  getQueryParamValue,
  redirectToPrevNextStepOfForm,
} from "../../../utils/helpers";
import { useLocation } from "react-router-dom";
import { integrationsData } from "../../../data/data";
import ApiService from "../../../api/ApiService";
import IntegrationImageTile, {
} from "../../../components/integration_image_tile/IntegrationImageTile";
import { MultiStepFormContext } from "../../../context/MuliStepFormContext";
import { toast } from "react-toastify";
import { ChooseIntegrationElementsWrapper, ConnectionButtonSection, ConnectionIconSection, IntegrationSection, Title } from "./style";
import { IntegrationTile } from "../../../components/integration_image_tile/style";

interface SourceProps {
  id: number;
  name: string;
  active: boolean;
}

const ChooseIntegrationElementsStep = (props: any) => {
  let location = useLocation();
  const queryParams = location.search;
  const { handleChangeFormStep } = props;
  const {contextData, setContextData} = useContext(MultiStepFormContext)
  const [connectlyAvailableSources, setConnectlyAvailableSources] =
    useState<SourceProps[]>();
  const [fromIntegrationId, setFromIntegrationId] = useState<string | null>(
    () => {
      if (getQueryParamValue(queryParams, "fromIntegrationId") !== null)
        return getQueryParamValue(queryParams, "fromIntegrationId");
      else return null;
    }
  );
  const [toIntegrationId, setToIntegrationId] = useState<string | null>(() => {
    if (getQueryParamValue(queryParams, "toIntegrationId") !== null)
      return getQueryParamValue(queryParams, "toIntegrationId");
    else return null;
  });

  const getConnectlyAvailableSources = () => {
    const apiService = new ApiService()
    apiService.getSourceList()
      .then((response) => {
        setConnectlyAvailableSources(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getConnectlyAvailableSources();
  }, []);

  const handleChoose = (integrationId: string) => {
    if (integrationId) setToIntegrationId(null);
      setFromIntegrationId(integrationId)
      toast.success(`Wybrano serwis ${integrationId}`)
      let chooseIntegrationElementsData = {
        fromIntegrationId: integrationId,
        toIntegrationId: toIntegrationId,
      };
      setContextData({...contextData, choose_integration_elements: chooseIntegrationElementsData})
  };

  const handleChooseTripsomnia = () => {
    if (!toIntegrationId) {
      setToIntegrationId("tripsomnia");
      toast.success(`Wybrano Tripsomnię`)
    }
    else setToIntegrationId(null);
  };

  const handleConnection = () => {
    let chooseIntegrationElementsData = {
      fromIntegrationId: fromIntegrationId,
      toIntegrationId: toIntegrationId,
    };
    setContextData({...contextData, choose_integration_elements: chooseIntegrationElementsData})
    redirectToPrevNextStepOfForm(
      location.search,
      chooseIntegrationElementsData,
      `/integrationForm/${fromIntegrationId}/fillIntegrationDetails?`,
      handleChangeFormStep
    );
  };

  return (
    <>
      <Title>Nowa integracja</Title>
      <ChooseIntegrationElementsWrapper>
        <IntegrationSection>
          <p>
            Połącz aplikację{" "}
            <strong>{fromIntegrationId ? fromIntegrationId : "..."}</strong>
          </p>
          {integrationsData.map((integrationData) => {
            if (fromIntegrationId === integrationData.id)
              integrationData.active = true;
            else integrationData.active = false;
            return (
              <IntegrationImageTile
                key={integrationData.id}
                data={integrationData}
                onChoose={handleChoose}
              />
            );
          })}
        </IntegrationSection>
        <ConnectionIconSection style={{ width: "15%", margin: "auto" }}>
          {fromIntegrationId && <FaPlusCircle size={32} />}
        </ConnectionIconSection>
        <IntegrationSection>
          {fromIntegrationId && (
            <div
              style={{ margin: "0 0 70px 0" }}
              key="tripsomnia"
              onClick={handleChooseTripsomnia}
            >
              <p>
                z aplikacją{" "}
                {toIntegrationId ? <strong>{toIntegrationId}</strong> : "..."}
              </p>
              <IntegrationTile
                visible={true}
                src="https://s3-us-west-2.amazonaws.com/cbi-image-service-prd/modified/a7fdaf8b-c6f1-4cde-bd1e-31daf4f9af51.png"
                active={toIntegrationId}
              />
            </div>
          )}
        </IntegrationSection>
        <ConnectionButtonSection>
          {fromIntegrationId && toIntegrationId && (
            <Button onClick={handleConnection}>Połącz</Button>
          )}
        </ConnectionButtonSection>
      </ChooseIntegrationElementsWrapper>
    </>
  );
};

export default ChooseIntegrationElementsStep;
