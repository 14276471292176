import React, { useContext, useState } from "react";
import ApiService from "../../../api/ApiService";
import { MultiStepFormContext } from "../../../context/MuliStepFormContext";
import Button from "../../buttons/Button";
import { ProductProps } from "../IntegrationProductTile";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../pages/style/main";
import { Spinner } from "../../../pages/integration_form_page/tour_cms/step_2/style";
import { ActionProductSummary, LabelBold, ProductInfoSummary, ProductIntegrationDetails, Value } from "./style";

export const getSourceNameById = (id:any) => {
  switch(Number(id)){
    case 1: return 'Yield Planet';
    case 2: return 'TourCMS';
    case 3: return 'Bokun';
    case 4: return 'Hotres';
    case 5: return 'Fare Harbor';
  }
}

export const getSlugBySourceId = (sourceId: number) => {
  switch(sourceId){
    case 1: return 'YIELD';
    case 2: return 'TourCMS';
    case 3: return 'Bokun';
    case 4: return 'Hotres';
    case 5: return 'FareHarbor'
  }
}

/*
 * Integration Product actions in tile (Actions buttons for each product in integration)
 */
const IntegrationProductInfoActions = (props: ProductProps) => {
  const {
    channelId,
    channelName,
    company,
    idInSource,
    idInTripsomnia,
    title,
    description,
    exist,
    isActive,
    price,
    saleCurrency,
    location,
    thumbnailImage,
    image,
    duration,
    fee,
    actualized,
    slug,
    companyId,
    updateProducts,
    noConnIntegration
  } = props;
  const {id, name} = company
  const {contextData, setContextData} = useContext(MultiStepFormContext)
  const [loadingAddTour, setLoadingAddTour] = useState(false)
  const [loadingActivityProduct, setLoadingActivityProduct] = useState(false)
  const [loadingActualizeProduct, setLoadingActualizeProduct] = useState(false)

  const activityProduct = () => {
    setLoadingActivityProduct(true)
    let activeProductData = {
      id: Number(idInTripsomnia),
      status: !isActive
    }
    const apiService = new ApiService()
    apiService.activeProduct(activeProductData)
      .then((response) => {
        updateProducts && updateProducts()
        toast.success(`Produkt id: ${idInTripsomnia} został ${activeProductData.status ? 'aktywowany' : 'dezaktywowany'}`, toastifyParameters)
      })
      .catch((err) => {
        toast.error(`Nie udało się ${activeProductData.status ? 'aktywować' : 'dezaktywować'} produkt id: ${idInTripsomnia}`, toastifyParameters)
      })
      .finally(() => {setLoadingActivityProduct(false)})
  }

  const addTour = () => {
    setLoadingAddTour(true)
    let addTourData = {
      sourceId: id,
      companyId: isNaN(contextData?.fill_integration_details?.partnerId) ? Number(companyId) : Number(contextData?.fill_integration_details?.partnerId),
      sourceTourId: [1,4].includes(company.id) ? Number(idInTripsomnia) : Number(idInSource),
      slug: [1,3,4].includes(company.id) ? getSlugBySourceId(Number(company.id)) : null
    };

    const apiService = new ApiService()
    apiService.addTour(addTourData)
      .then((response) => {
        toast.success(`Produkt został dodany pomyślnie !`, toastifyParameters)
        updateProducts && updateProducts()
      })
      .catch(() => {
        toast.error(`Nie udało się dodać produktu id: ${idInSource} (z TourCMS) do Tripsomni`, toastifyParameters)
      })
      .finally(() => {setLoadingAddTour(false)})
  };

  const actualizeProduct = () => {
    setLoadingActualizeProduct(true)
    let actualizeProductData = {
      id: Number(idInTripsomnia)
    }
    const apiService = new ApiService()
    apiService.actualizeProduct(actualizeProductData)
      .then((response) => {
        updateProducts && updateProducts()
        toast.success(`Zaktualizowano produkt id: ${idInTripsomnia}`, toastifyParameters);
      })
      .catch((err) => {
        toast.error(`Nie udało się aktualizować produktu id: ${idInTripsomnia}`, toastifyParameters)
      })
      .finally(() => {setLoadingActualizeProduct(false)})
  }

  return (
    <ProductIntegrationDetails>
      <ProductInfoSummary>
        <LabelBold>Oferta zaimportowana: <Value>{idInTripsomnia ? 'Tak' : 'Nie'}</Value></LabelBold>
        <LabelBold>{getSourceNameById(company?.id)} Id: <Value>{idInSource}</Value></LabelBold>
        <LabelBold>Tripsomnia Id: <Value>{idInTripsomnia || '-'}</Value></LabelBold>
      </ProductInfoSummary>
      <ActionProductSummary>
        <LabelBold>Akcja</LabelBold>
        {!idInSource && <Button onClick={addTour} disabled={isNaN(Number(idInSource)) || idInSource !== null}>{loadingAddTour ?  <span>Dodawanie... <Spinner size='12px' color='white' /></span> : 'Dodaj'}</Button>}
        {idInSource && <Button onClick={activityProduct}>{loadingActivityProduct ? <span>{isActive ? 'Wyłączanie oferty...' : 'Włączanie oferty...'} <Spinner size='12px' color='white' /></span> : isActive ? 'Wyłącz' : 'Włącz'}</Button>}
        {exist && !noConnIntegration && <Button onClick={actualizeProduct}>{loadingActualizeProduct ? <span>Aktualizowanie... <Spinner size='12px' color='white' /></span>: 'Aktualizuj'}</Button>}
      </ActionProductSummary>
    </ProductIntegrationDetails>
  );
};

export default IntegrationProductInfoActions;
