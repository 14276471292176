import axios, { AxiosInstance } from "axios";

export var tripsomniaUrlPrefix = "https://tripsomnia.com/api"

if (process.env.REACT_APP_ENV === "production") {
  tripsomniaUrlPrefix = "https://old.tripsomnia.com/api"
}
if (process.env.REACT_APP_ENV === "staging") {
  tripsomniaUrlPrefix = "https://old.s.tripsomnia.com/api"
}

/*
 * API interfaces data props
 */

interface GetUserDetails {
    token: string,
    email: string,
    apiVersion: number
}

/*
 * Tripsomnia API Service (store all endpoints)
 */
class TripsomniaApiService {
  /* POST */
  private axiosInstance: AxiosInstance;

  constructor() {

    this.axiosInstance = axios.create();
  }

  getUserDetails = async (data: GetUserDetails) => {
    return await this.axiosInstance.get(
      `${tripsomniaUrlPrefix}/partner/account`, {
        headers: {
          'X-Admin-Email': data.email,
          'X-Admin-Token': data.token,
          'X-Admin-Api': data.apiVersion
        }
      }
    )
  }
  
}

export default TripsomniaApiService;
