import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../../../../api/ApiService";
import Button from "../../../../components/buttons/Button";
import IntegrationProductTile, {
  ProductProps,
} from "../../../../components/integration_product_tile/IntegrationProductTile";
import { MultiStepFormContext } from "../../../../context/MuliStepFormContext";
import { redirectToPrevNextStepOfForm } from "../../../../utils/helpers";
import Pagination from "../../../../components/pagination/Pagination";
import { toast } from "react-toastify";
import { toastifyParameters } from "../../../style/main";
import { ProcessingSpinner, Spinner } from "../step_2/style";
import { ButtonGroup, IntegrationProducts, IntegrationProductsPanel, PaginationPanel, View } from "../../style/style";
import { IntegrationTile } from "../../../../components/integration_image_tile/style";

const IntegrationProductsStep = (props: any) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { contextData, setContextData } = useContext(MultiStepFormContext);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [actualized, setActualized] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductProps[]>();
  const [productSize, setProductSize] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data, handleChangeFormStep } = props;
  const integrationProductsRef = useRef<HTMLDivElement>(null);

  const getProducts = () => {
    if (products && products.length === 0) setLoading(true);
    let data = {
      companyId: Number(contextData?.fill_integration_details?.partnerId),
      sourceId: 2,
      channelId: Number(contextData?.fill_integration_details?.channelId),
      page: page,
      size: size,
    };
    const apiService = new ApiService()
    apiService.getProducts(data)
      .then((response) => {
        if (products && products.length === 0) {
          toast.success(
            `Pomyślnie pobrano produkty z channel ${Number(
              contextData?.fill_integration_details?.channelId
            )} ! (Strona: ${page} ; Ilość na stronie: ${size})`
          );
        }
        setProducts(response.data.list);
        setProductSize(Number(response.data.rowCount));
      })
      .catch((err) => {
        toast.error(
          `Pobranie produktów z channelu ${Number(
            contextData?.fill_integration_details?.channelId
          )} nie powiodło się !`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (integrationProductsRef.current) {
      integrationProductsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [page]);

  useEffect(() => {
    if (
      contextData?.fill_integration_details?.partnerId !== null &&
      contextData?.fill_integration_details?.channelId !== null
    )
      getProducts();
  }, [page, size, actualized, contextData?.fill_integration_details]);

  const handleBack = () => {
    if (actualized) setActualized(false);
    else {
      let data = {
        integrationProductsComplete: true,
      };
      setContextData({ ...contextData, integration_products: data });
      redirectToPrevNextStepOfForm(
        location.search,
        data,
        "/integrationForm/tourCMS/fillIntegrationDetails?",
        handleChangeFormStep
      );
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageSizeChange = (size: number) => {
    setSize(size);
    setPage(1);
  };

  const updateProducts = () => {
    getProducts();
  };

  const addAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (!product?.exist) {
          let addTourData = {
            sourceId: product?.company?.id,
            companyId: Number(contextData?.fill_integration_details?.partnerId),
            sourceTourId: Number(product?.idInSource),
            slug: null,
          };
          const apiService = new ApiService()
          const request = apiService.addTour(addTourData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(
            `Wszystkie produkty zostały dodane pomyślnie !`,
            toastifyParameters
          );
        } else {
          toast.error(
            `Nie udało się dodać wszystkich produktów (z TourCMS) do Tripsomni`,
            toastifyParameters
          );
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(
          `Nie udało się dodać wszystkich produktów (z TourCMS) do Tripsomni`,
          toastifyParameters
        );
      });
  };

  const actualizeAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist) {
          let actualizeProductData = {
            id: Number(product?.idInTripsomnia),
          };
          const apiService = new ApiService()
          const request = apiService.actualizeProduct(actualizeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        if (allActivated) {
          toast.success(
            `Zaktualizowano wszystkie produkty !`,
            toastifyParameters
          );
        } else {
          toast.error(
            `Nie udało się zaktualizować wszystkich produktów`,
            toastifyParameters
          );
        }
      })
      .catch(() => {
        toast.error(
          `Nie udało się zaktualizować wszystkich produktów`,
          toastifyParameters
        );
      });
  };

  const inactiveAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist && product?.isActive) {
          let activeProductData = {
            id: Number(product?.idInTripsomnia),
            status: false,
          };
          const apiService = new ApiService()
          const request = apiService.activeProduct(activeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(
            `Wszystkie produkty zostały zdezaktywowane`,
            toastifyParameters
          );
        } else {
          toast.error(
            `Nie udało się dezaktywować wszystkich produktów`,
            toastifyParameters
          );
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(
          `Nie udało się dezaktywować wszystkich produktów`,
          toastifyParameters
        );
      });
  };

  const activeAllTours = () => {
    setIsProcessing(true);
    const requests: any[] = [];
    let allActivated = true;
    products &&
      products?.forEach((product) => {
        if (product?.exist && !product?.isActive) {
          let activeProductData = {
            id: Number(product?.idInTripsomnia),
            status: true,
          };
          const apiService = new ApiService()
          const request = apiService.activeProduct(activeProductData)
            .then(() => {})
            .catch(() => {
              allActivated = false;
            });
          requests.push(request);
        }
      });
    Promise.all(requests)
      .then(() => {
        updateProducts();
        setIsProcessing(false);
        if (allActivated) {
          toast.success(
            `Wszystkie produkty zostały aktywowane !`,
            toastifyParameters
          );
        } else {
          toast.error(
            `Nie udało się aktywować wszystkich produktów`,
            toastifyParameters
          );
        }
      })
      .catch(() => {
        updateProducts();
        setIsProcessing(false);
        toast.error(
          `Nie udało się aktywować wszystkich produktów`,
          toastifyParameters
        );
      });
  };

  return (
    <View isProcessing={isProcessing}>
      <ButtonGroup>
        <IntegrationTile
          src={
            "https://cdn.tourdesk.io/blog/post/0f0b8144-f963-40b4-b1b1-db500f333ce6.png"
          }
          width={50}
          height={30}
          active={true}
          visible={true}
        />
        <p style={{ marginRight: "2vh" }}>
          Produkty dostępne dla kanału{" "}
          <strong>{contextData?.fill_integration_details?.channelId}</strong>
        </p>
        <Button onClick={handleBack}>Wstecz</Button>
        <Button
          onClick={() => {
            navigate("/integrations_list");
          }}
        >
          Zakończ
        </Button>
      </ButtonGroup>
      <IntegrationProductsPanel>
        <Button onClick={addAllTours}>Dodaj wszystkie</Button>
        <Button onClick={actualizeAllTours}>Aktualizuj wszystkie</Button>
        <Button onClick={inactiveAllTours}>Wyłącz wszystkie</Button>
        <Button onClick={activeAllTours}>Włącz wszystkie</Button>
        <div
          style={{
            marginLeft: "5px",
            fontWeight: "bold",
            color: "#006600",
            fontSize: "20px",
          }}
        >
          Prowizja: {contextData?.fill_integration_details?.fee}%
        </div>
      </IntegrationProductsPanel>
      <IntegrationProducts ref={integrationProductsRef}>
        {!loading ? (
          products?.map((product) => {
            return (
              <IntegrationProductTile
                key={product.idInSource}
                actualized={actualized}
                {...product}
                updateProducts={updateProducts}
              />
            );
          })
        ) : (
          <div
            style={{
              fontSize: "20px",
              color: "#72bf6a",
              display: "flex",
              margin: "5vh auto",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "700",
            }}
          >
            Ładowanie produktów... <Spinner size="20px" color="#72bf6a" />
          </div>
        )}
        {isProcessing && <ProcessingSpinner isProcessing={isProcessing} />}
      </IntegrationProducts>
      <PaginationPanel>
        <Pagination
          currentPage={page}
          totalPages={
            !isNaN(Number(productSize))
              ? Math.ceil(Number(productSize) / size)
              : 1
          }
          onPageChange={handlePageChange}
          buttonStyle={{ border: "1px solid gray" }}
          activeButtonStyle={{ backgroundColor: "#72bf6a" }}
          getRowsPerPageCallback={handlePageSizeChange}
          rowsPerPageOptions={[1, 2, 5, 10, 20, 50]}
          defaultRowsPerPage={10}
        />
      </PaginationPanel>
    </View>
  );
};

export default IntegrationProductsStep;
