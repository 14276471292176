import React from 'react';
import styled from 'styled-components';
import Dropdown from '../buttons/DropdownButton';
import { Avatar, HeaderButtonsWrapper, HeaderWrapper, Logo } from './style';

export interface HeaderButtonProps {
  label: string;
  endpoint: string;
}

interface HeaderProps {
  logo: string;
  buttons: HeaderButtonProps[];
  avatar: string;
}

const Header: React.FC<HeaderProps> = ({ logo, buttons, avatar }) => {
  return (
    <HeaderWrapper>
      <Logo src={logo} alt="Logo" />
      <HeaderButtonsWrapper>
        <Dropdown buttons={buttons} />
      </HeaderButtonsWrapper>
      <Avatar src={avatar} alt="Avatar" />
    </HeaderWrapper>
  );
};

export default Header;