import styled from 'styled-components'

export const View = styled.div`
  opacity: ${(props: any) => (props.isProcessing ? 0.5 : 1)};
`;

export const ButtonGroup = styled.div`
  justify-conent: center;
  margin: auto;
  display: flex;
  align-items: center;
`;

export const IntegrationProductsPanel = styled.div`
  position: sticky;
  display: flex;
  background: white;
  padding: 3px 8px;
  top: 0;
  width: 100%;
  align-items: center;
  z-index: 999;
  border-bottom: 1px solid #d0d0d0;
`;

export const PaginationPanel = styled.div`
  position: sticky;
  background: white;
  bottom: 0;
  width: 100%;
  z-index: 999;
  margin: 0 !important;
`;

export const IntegrationProducts = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 60vh;
  margin: auto;
  width: 100%;
  align-items: center;
  justify-conent: center;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
`;

export const FeeForm = styled.div``;

export const LabelBold = styled.span`
  margin: 3px 5px;
  text-align: center;
  font-size: 13px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const Value = styled.span`
  font-size: 13px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const InputFee = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 15%;
  text-align: center;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
`;